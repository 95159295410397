.container {
  z-index: 1;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
}

.navbar {
  background: white;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.9rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 80px;
  box-shadow: 0px -2px 10px lightgrey;
}

.navbar-logo {
  flex-shrink: 0;
  margin-right: 18.5%;
  color: #252e4d;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 3rem;
  display: flex;
  align-items: center;
}

.navbar-icon {
  margin: 0.5rem;
  color: #252e4d;
  font-size: 4rem;
}
.nav-links {
  font-size: 2.2rem;
  flex-direction: row;
  color: #252e4d;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-item {
  height: 80px;
}

.nav-item:active {
  cursor: pointer;
}

.nav-item:hover {
  cursor: pointer;
}

@media screen and (min-width: 1030px) {
  .menu-icon {
    display: none;
  }
  .nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: flex-end;
  }

  .nav-item:active {
    border: none;
    color: rgb(1, 122, 155);
  }
}

.navbar-icon {
  color: #252e4d;
  font-size: 3.1rem;
}

.fa-bars {
  color: #252e4d;
}
.fa-Times {
  color: #252e4d;
}

.DiCssDeck {
  color: #252e4d;
}

.btn--outline {
  font-size: 2.2rem;
  background-image: linear-gradient(
    160deg,
    var(--btn-color-2),
    var(--first-color)
  );
  border-radius: 1rem;
  color: white;
  padding: 8px 20px;
  border: 0px;
  transition: all 0.3s ease-out;
  box-shadow: var(--bx-shdw) 8px 10px 20px 0px;
}
.btn--outline:hover {
  cursor: pointer;
  transition: all 0.2s ease-in;
  transform: scale(1.02);
}

.btn-link {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;
  height: 100%;
  width: 100%;
}

/* Smaller Screens */

@media screen and (max-width: 1030px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #fff;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
    color: #4b59f7;
  }

  .nav-menu.active:hover {
    background: #fff;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
    color: rgb(1, 122, 155);
  }

  .nav-links {
    flex-direction: row;
    color: #252e4d;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }

  .nav-item {
    position: relative;
  }

  .nav-links:hover {
    color: rgb(1, 122, 155);
    transform: scale(1.1);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
    color: rgb(1, 122, 155);
  }

  .nav-item {
    width: 100%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 3rem;
    cursor: pointer;
    color: #252e4d;
  }

  .navbar-icon svg {
    font-size: 3rem;
  }

  .menu-icon svg {
    font-size: 3rem;
    margin-top: 0.4rem;
  }

  .fa-times {
    color: #252e4d;
    font-size: 3rem;
  }

  .nav-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
  }

  .nav-btn:hover {
    color: rgb(1, 122, 155);
  }
}

.nav-links-mobile {
  display: block;
  text-align: center;
  margin: 2rem auto;
  border-radius: 20px;
  width: 80%;
  text-decoration: none;
  font-size: 1.5rem;
  background-color: transparent;
  color: #252e4d;
  padding: 14px 20px;
  border: 1px solid #252e4d;
  transition: all 0.3s ease-out;
}

/* For tiny screens */

@media screen and (max-width: 600px) {
  .navbar-icon {
    margin: 0.1rem 0.1rem 0.1rem 0.1rem;
  }

  .navbar-logo {
    margin: 0.1rem 0.1rem 0.1rem 0.1rem;
    position: relative;
    display: flex;
    flex-shrink: 1;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 1;
    transform: translate(0%, 0%);
    font-size: 2.5rem;
  }

  .navbar-container .container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .menu-icon svg {
    margin-top: 0.4rem;
  }
}
