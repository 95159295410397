.container .section-container {
  display: flex;
  margin: 1.5rem 1rem 0rem 1rem;
  height: 675px;
  width: auto;
  padding: 1rem 1rem 1rem 1rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  background: linear-gradient(134deg, #40cbe4, #4688e3);
}
