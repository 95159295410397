.technologies-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: clamp(100rem, 120rem, 100%);
  width: 100%;
  background-color: var(--bg-color-2);
}

.content-wrapper {
  width: 100%;
  max-width: 1400px;
}

.tech-items-container {
  display: grid;
  grid-template-rows: repeat(3, 25rem);
}

.tech-items {
  margin: 3rem 0 2rem 3rem;
  border-left: 0.5rem solid #ffa134;
  padding: 0 20px;
  display: grid;
  gap: 1rem;
  width: auto;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
}

.tech-items:hover {
  transition: all 0.2s ease-in;
  transform: scale(1.1);
}
.tech-icons {
  color: #252e4d;
}

.tech-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.tech-btn {
  margin: 5rem 3rem 0 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  background-image: linear-gradient(
    160deg,
    var(--btn-color-2),
    var(--first-color)
  );
  border-radius: 0.8rem;
  width: calc(100vw - 6rem);
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0.8rem 4rem;
  color: #fff;
  font-size: 2.5rem;
  font-weight: bold;
  box-shadow: var(--bx-shdw) 8px 10px 20px 0px;
}

.tech-btn:hover {
  cursor: pointer;
  transition: all 0.2s ease-in;
  transform: scale(1.02);
}

@media screen and (max-width: 600px) {
  .tech-heading {
    font-size: 5rem !important;
  }
  .tech-btn-container {
    width: 90%;
  }
  .tech-btn {
    width: 90%;
  }
}

/* For smaller screens */

@media screen and (max-width: 1030px) {
  .tech-heading {
    margin: 5rem 0 3rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tech-items-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .tech-items {
    display: flex;
    flex-direction: row;
    flex-basis: content;
    justify-content: space-around;
    min-width: 25rem;
    min-height: 20rem;
    overflow: visible;
    margin-left: 0;
  }

  .tech-item-row {
    display: flex;
    width: 90%;
    justify-content: center;
    align-items: center;
  }

  .tech-icons {
    display: none;
  }

  .tech-items-heading {
    font-size: 4rem;
  }

  .tech-items .tech-text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tech-btn-container {
    width: 100%;
    margin: 0.1rem 0.1rem 0.1rem 0.1rem;
  }

  .tech-btn {
    display: block;
    width: 90vw;
    margin: 3rem 0.1rem 2rem 0.1rem;
    height: 8rem;
  }
}

/* Desktop Screens */

@media screen and (min-width: 1030px) {
  .tech-icons {
    margin-bottom: 1rem;
  }

  .tech-items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    max-width: 1600px;
    padding: 2rem;
  }

  .tech-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 25rem;
    min-height: 25rem;
  }

  .tech-heading {
    display: inline-block;
    width: auto;
    margin: 20rem 0 5rem 5rem;
  }

  .tech-btn-container {
    display: block;
    height: 15rem;
    margin-top: 5rem;
  }

  .tech-btn {
    display: block;
    width: 38rem;
    margin: 3rem 0 0 4rem;
    height: 6rem;
  }
}

/* .tech-item-1 {
}

.tech-item-2 {
}

.tech-item-3 {
} */

.tech-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #87888d;
}

.tech-items-heading {
  font-size: 3rem;
  line-height: 1.6;
  font-weight: 600;
  margin-bottom: 2rem;
}

.tech-items h3 {
  margin-left: 0.2rem;
  max-width: 440px;
  font-size: 2rem;
  line-height: 3rem;
  color: #252e4d;
}
