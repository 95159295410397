.contact__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 4rem;
}

.email,
.name,
.message,
.submit {
  padding: 0.7rem 0.9rem;
  border-radius: 1rem;
  margin: 0.8rem;
  outline: none;
  border: none;
  font-size: 2rem;
  border: 0.2rem solid #fff;
  width: 80%;
  outline: 0.1rem solid rgb(206, 206, 206);
  box-shadow: var(--bx-shdw) 8px 10px 20px 0px;
  text-align: left;
}

.submit {
  background: linear-gradient(316deg, #3023ae, #c86dd7);
  background: linear-gradient(134deg, #3023ae, #c86dd7);
  color: #fff;
  font-size: 3rem;
  box-shadow: var(--bx-shdw) 8px 10px 20px 0px;
  border-radius: 0.8rem;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 2.5rem;
  font-weight: bold;
  margin: 5rem 3rem 0 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
}

.submit:hover {
  cursor: pointer;
  transition: all 0.2s ease-in;
  transform: scale(1.02);
  box-shadow: var(--bx-shdw) 8px 10px 20px 0px;
}

input {
  padding: '';
  height: 4rem;
}

.response-message {
  font-size: 5rem;
}
