.info-section-container {
  display: flex;
  flex-direction: column;
  color: #fff;
  background-image: linear-gradient(
    to bottom,
    var(--bg-color-2),
    var(--bg-color-3)
  );
  padding: 2rem 0;
  padding-right: 3rem;
  padding-left: 3rem;
  margin: -1rem;
  z-index: 1;
}

.test-video {
  width: 100%;
  min-width: 130rem;
  /* min-height: 100%; */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0.5;
}

.gradient {
  height: 100%;
  width: 100%;
}

.info-section {
  background: linear-gradient(160deg, #01b3e4, #e2c6ff);
  position: relative;
  max-width: 1400px;
  height: auto;
  width: 100%;
  margin: 5vh auto 10vh auto;
  display: flex;
  padding: 10rem 5rem 0 5rem;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  z-index: 1;
  border-radius: 0.75rem;
  overflow: hidden;
  box-shadow: var(--bx-shdw) 8px 10px 20px 0px;
}

.info-section-2 {
  margin: 10vh auto 10rem auto;
  display: flex;
  padding: 10rem 5rem 15rem 5rem;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  z-index: 1;
  width: 100%;
  height: auto;
  max-width: 1400px;
  border-radius: 0.75rem;
  background: linear-gradient(90deg, #bfa1ea, #fd88a9 99%);
  box-shadow: var(--bx-shdw) 8px 10px 20px 0px;
}

.info-row {
  display: flex;
  height: 100%;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: row;
  flex-grow: 3;
  max-height: 675px;
}

.info-column {
  display: flex;
  height: 100%;
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  align-items: center;
  align-content: space-around;
  justify-content: center;
  flex: 1;
  max-width: 50%;
  flex-grow: 3;
  max-height: 675px;
  flex-basis: 50%;
}

.text-wrapper {
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
}

.top-line {
  color: '#a9b3c1';
  font-size: 2.4rem;
  line-height: 1.5rem;
  font-weight: 700;
  letter-spacing: 0.2rem;
  margin-bottom: 1rem;
}

.heading {
  font-size: 4.5rem;
  line-height: 1.1;
  font-weight: 600;
  color: #f7f8fa;
}

.subtitle {
  margin-left: 0.2rem;
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 2rem;
  line-height: 2.8rem;
  color: '#a9b3c1';
}

.btns-link {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
}

.btn,
.btn2 {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 50px;
  width: 100%;
  max-width: 320px;
  box-shadow: var(--bx-shdw) 8px 10px 20px 0px;
  outline: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
  white-space: nowrap;
  padding: 1.5rem 16rem;
  color: #fff;
  font-size: 20px;
  border-radius: 0.8rem;
}

.btn {
  background: linear-gradient(320deg, #5c74ec, #0467fb);
}

.btn:hover {
  transition: all 0.3s ease-out;
  background: #fff;
  background: linear-gradient(134deg, #6474fa, #0467fb);
  box-shadow: rgb(46 61 73 / 15%) 8px 10px 20px 0px;
  transition: all 0.1s ease-in;
  transform: scale(1.05);
}

.btn2 {
  background: linear-gradient(134deg, #418dff, #9763ff);
}

.btn2:hover {
  transition: all 0.3s ease-out;
  background: #fff;
  background-color: #0467fb;
  background: linear-gradient(320deg, #418dff, #9763ff);
  box-shadow: rgb(46 61 73 / 15%) 8px 10px 20px 0px;
  transition: all 0.1s ease-in;
  transform: scale(1.05);
}

.img-wrapper {
  max-width: 555px;
  display: flex;
  justify-content: flex-start;
}

.info-image {
  display: flex;
  padding-right: 0;
  border: 0;
  min-width: 50%;
  max-width: 100%;
  vertical-align: flex-start;
  align-items: center;
  max-height: 500px;
  justify-content: center;
}

/* For tiny screens */

@media screen and (max-width: 400px) {
  .info-section,
  .info-section-2 {
    margin: 0.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5rem 3rem 4rem 3rem;
  }

  .info-section-2 .subtitle,
  .top-line {
    font-size: 2rem;
    line-height: normal;
    margin: 3rem 1rem 4rem 1rem;
    text-align: left;
  }

  .subtitle,
  .top-line {
    letter-spacing: 0.1rem;
    font-size: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0.1rem;
    margin-right: 0.1rem;
  }

  .text-wrapper {
    width: 100%;
  }

  .heading {
    padding: 1rem;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    margin-top: 1rem;
  }

  .btn {
    min-width: 100%;
  }

  .btn,
  .btn2 {
    height: 6rem;
    text-decoration: none;
    /* max-width: 320px; */
    outline: none;
    border: none;
    font-weight: bold;
    white-space: nowrap;
  }
  .info-image {
    max-width: 320px;
  }

  .btns-link {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .btn2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

/*Desktop */

@media screen and (min-width: 991px) {
  .info-sections {
    aspect-ratio: 16/9;
    padding-bottom: 10rem;
    max-width: 1200px;
    max-height: 600px;
  }

  .info-section-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media screen and (max-width: 768px) {
  .info-column {
    height: auto;
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }

  .text-wrapper {
    padding-bottom: 65px;
  }

  .test-video {
    min-width: 160rem;
  }
}
@media screen and (max-width: 1030px) {
  .btn,
  .btn2 {
    padding: 1.5rem 10rem;
  }
  .info-row {
    align-items: center;
  }
}
