/* NOTE * some styles are insise the Card component. */

.proj-container {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f3f8fd;
  padding: 2rem;
  padding-right: 3rem;
  padding-left: 3rem;
  margin: -1rem;
  margin-bottom: 0;
}

.white-wrapper {
  margin-top: 4rem;
  z-index: 1;
  display: grid;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 1300px;
  border-radius: var(--radius);
  background: #fff;
  box-shadow: rgb(46 61 73 / 15%) 0px 0px 25px 5px;
  margin-bottom: 20rem;
}

.proj-heading-card {
  margin-top: 30rem;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.proj-heading {
  margin: 15rem 15rem 0rem 15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10rem;
  width: 100%;
}

.proj-heading-card .proj-heading {
  font-size: 8rem;
}

.contact-btn-container {
  display: flex;
  width: 100%;
}

.contact-btn {
  margin: 2rem;
  height: 8rem;
  background-image: linear-gradient(
    160deg,
    var(--btn-color-2),
    var(--first-color)
  );
  width: 100%;
  outline: none;
  border: none;
  border-radius: 0.8rem;
  cursor: pointer;
  padding: 0.8rem 4rem;
  color: #fff;
  font-size: 2.5rem;
  font-weight: bold;
  box-shadow: var(--bx-shdw) 5px 10px 20px 15px;
}

.contact-btn:hover {
  cursor: pointer;
  transition: all 0.2s ease-in;
  transform: scale(1.02);
}

/* For tiny Screens */

@media screen and (max-width: 760px) {
  .proj-heading-card {
    display: flex;
    height: 10rem;
  }
  .proj-heading {
    font-size: 6rem;
    display: block;
  }

  .proj-container {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .white-wrapper {
    width: 95%;
    display: flex;
    flex-direction: column;
  }

  .contact-btn-container {
    width: 90%;
  }

  .contact-link {
    width: 100%;
  }

  .contact-btn {
    width: 100%;
  }
}

/* Desktop */

@media screen and (min-width: 1030px) {
  .proj-heading-card {
    margin-bottom: 0;
  }

  .proj-heading {
    justify-content: flex-end;
    margin: 12rem 0rem 0rem 11.5rem;
    /* width: 100%; */
    font-size: 8.5rem !important;
    letter-spacing: 0.12em;
  }

  /* .proj-heading:before {
    content: '';
    background: linear-gradient(90deg, #252e4d, #01b3e4);
    width: 36rem;
    height: 0.15em;
    position: relative;
    bottom: -4.3rem;
    left: 51rem;
    z-index: -1;
    border-radius: 2rem;
  } */
  .contact-btn-container {
    display: block;
    width: 100%;
    margin: 0 0 2rem 0;
  }
  .contact-link {
    margin-bottom: 2rem;
    display: flex;
    max-width: 38rem;
    margin: 1rem 0 1rem 53rem;
  }
  .contact-btn,
  .contact-link {
    padding: 1rem;
  }

  .contact-btn {
    display: block;
    margin: 0 !important;
    padding: 1rem;
    height: 6rem;
    width: 38rem;
    height: 6rem;
  }
}
