.wrapper {
  width: auto;
  display: grid;
  margin: 2rem;
  gap: 2rem;
  grid-template-columns: 1fr;
  justify-content: center;
}

.card__img {
  margin: 4rem;
  padding-right: 0;
  max-width: 90%;
  vertical-align: middle;
  display: inline-block;
  max-height: 23rem;
  object-fit: cover;
}

.card {
  padding: 1rem;
  margin: 2rem;
  box-shadow: var(--bx-shdw) 5px 10px 20px 02px;
  border-radius: var(--radius);
  min-width: 28rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
}

.card-accent-1 {
  border-top: 0.5rem solid var(--first-color);
}
.card-accent-2 {
  border-top: 0.5rem solid var(--second-color);
}
.card-accent-3 {
  border-top: 0.5rem solid var(--fifth-colior);
}

.card__body {
  letter-spacing: 0.15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 2rem 2.2rem 2.2rem 2rem;
  height: 100%;
}

.card__title {
  margin-top: 0.5rem;
  min-height: 3rem;
  max-height: 4rem;
  font-size: 3rem;
  line-height: 3rem;
}

.card__description {
  display: block;
  font-size: 2rem;
  line-height: 2.4rem;
  letter-spacing: 0.1em;
}

.card-btn-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.card__link {
  text-decoration: none;
}

.card__btn {
  display: block;
  color: #555b6d;
  font-family: 'IBM Plex Sans', sans-serif;
  border: none;
  border-radius: 5px;
  background: inherit;
  white-space: nowrap;
  font-size: 1.8rem;
  outline: none;
  cursor: pointer;
  width: 47%;
  height: 4.5rem;
  box-shadow: var(--bx-shdw) 3px 5px 12px 2px;
}

.card__btn:hover {
  background: #555b6d3f;
  transition: all 0.2s ease-in;
}

/* .response-message {
  font-size: 5rem;
} */

/* For tiny Screens */

@media screen and (max-width: 760px) {
  .wrapper {
    margin: 0.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .card {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 22rem;
    max-width: 95%;
  }
  .card__body {
    display: flex;
    max-width: 90%;
    letter-spacing: 0.1rem;
    padding: 1rem;
    margin: 0.5rem;

    width: 90%;
  }

  .card__title {
    font-size: 2.6rem;
    line-height: 2.5rem;
  }

  .card__description {
    line-height: 2.4rem;
    font-size: 2rem;
    margin: 2rem;
  }

  .card__img {
    display: none;
  }

  .card-btn-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .card__btn {
    margin-top: 1rem;
    height: 5rem;
    width: 100%;
  }
}

/* For Desktop */

@media screen and (min-width: 1030px) {
  .wrapper {
    margin: 1rem;
    padding: 1rem;
    grid-template-columns: repeat(3, 1fr);
    height: clamp(40%, 65rem, 100%);
    justify-content: center;
    gap: 0.2rem;
  }

  .card {
    margin: 1rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    max-height: 48rem;
    min-height: 40rem;
    min-width: 26rem;
    max-width: 32rem;
    justify-content: space-evenly;
    align-items: center;
  }

  .card__img {
    padding: 0;
    margin: 1rem;
    max-width: auto;
    height: auto;
    padding: 2rem 2rem 2rem 2rem;
    filter: drop-shadow(8px 12px 10px var(--bx-shdw));
  }

  .card__title {
    font-size: 3.2rem;
  }

  .card-btn-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .card__link,
  .card__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95%;
    height: 4rem;
  }

  .card__description {
    padding: 1rem;
    display: block;
    font-size: 1.7rem;
    line-height: 2.4rem;
    letter-spacing: 0.08em;
  }
}
