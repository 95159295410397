html {
  scroll-behavior: smooth;
  font-size: 62.5%;
}

:root {
  --bg-color-1: #fff;
  --bg-color-2: #f3f8fd;
  --bg-color-3: rgba(169, 81, 237, 0.1);
  --font-color-1: #252e4d;
  --btn-color-1: #252e4d;
  --btn-color-2: #017a9b;
  --first-color: #01b3e4;
  --second-color: #cd9cff;
  --third-color: #bfa1ea;
  --fourth-color: #fd88a9;
  --fifth-colior: #ffa134;
  --sixth-color: #a151ed;
  --bx-shdw: rgb(46 61 73 / 15%);
  --radius: 0.75rem;
}

.sticky {
  position: sticky;
  top: 0;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Mulish', sans-serif;
  font-size: 62.5%;
}

.section-heading {
  font-size: 6rem;
  line-height: 0.8;
  font-weight: 600;
  color: #252e4d;
  font-size: 6rem;
}

.header {
  font-size: 2rem;
}

.container {
  background-color: #fff;
  z-index: 1;
  width: 100%;
  max-width: 4000px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
}

@media screen and (max-width: 990px) {
  .container {
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
  }
}
