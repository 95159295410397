.footer-container {
  background: linear-gradient(to bottom right, #fff 0, rgba(169, 81, 237, 0.1));
  padding: 4rem 0 0rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.grid-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.footer-contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  color: #252e4d;
}

.footer-heading-1 {
  margin-bottom: 5rem;
  font-size: 2.5rem;
  text-align: center;
}

.footer-subheading {
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  text-align: center;
}

.footer-subtext {
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 4rem;
}

.section-title {
  margin: 5rem 15rem 3rem 15rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact__form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.socials-grid {
  display: grid;
  grid-template-columns: repeat(2, 20rem);
  grid-template-rows: repeat(2, 20rem);
  gap: 1rem;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.social-icons {
  size: 2em;
}

.bottom-text {
  margin: 1rem;
  text-align: center;
  font-size: 1.5rem;
}

.bottom-content {
  margin-top: 4rem;
  background-image: linear-gradient(
    90deg,
    var(--first-color),
    var(--sixth-color)
  );

  min-height: 5rem;
  height: auto;
  width: 100%;
  font-size: 2rem;

  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .bottom-text {
    font-size: 2.5rem;
  }

  .bottom-content {
    height: 12rem;
  }
}

@media screen and (min-width: 1030px) {
  .grid-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    max-width: 1400px;
  }
  .bottom-text {
    margin-right: 5rem;
    text-align: right;
  }

  .bottom-content {
    justify-content: center;
    align-items: flex-end;
  }
}
